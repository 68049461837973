import Layout from "layouts/Layout.vue"
import InnerLayout from "layouts/InnerLayout.vue"
import { Home, Login, Logout, Registration, NotFound, Forgot, Reset, Confirm, WikiArticle, Invoice } from "components"
import { Features, Pricing, GoTo } from "components"
import { Dashboard, Goods, Billing, Settings, Plan, ChoosePlan, Pay } from "components/inner"

export default [
    {
        path: "/",
        name: "Outer",
        redirect: "/",
        component: Layout,
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
                meta: {
                    title: 'Home'
                },
            },
            {
                path: "/features",
                name: "Features",
                component: Features,
                meta: {
                    title: 'Features'
                },
            },
            {
                path: "/pricing",
                name: "Pricing",
                component: Pricing,
                meta: {
                    title: 'Pricing'
                },
            },
            {
                path: "/help/*",
                name: "WikiArticle",
                component: WikiArticle,
                meta: {
                    title: 'Help'
                },
            },
        ]
    },
    {
        path: "/goods",
        name: "Inner",
        redirect: "/goods",
        component: InnerLayout,
        children: [
            {
                path: "",
                name: "Goods",
                component: Goods,
                meta: {
                    title: 'Goods'
                },
            },
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    title: 'Dashboard'
                },
            },
            {
                path: "/billing",
                name: "Billing",
                component: Billing,
                meta: {
                    title: 'Billing'
                },
            },
            {
                path: "/billing/pay",
                name: "Pay",
                component: Pay,
                meta: {
                    title: 'Pay'
                },
            },
            {
                path: "/plan",
                name: "Plan",
                component: Plan,
                meta: {
                    title: 'Plan'
                },
            },
            {
                path: "/plan/choose/:id",
                name: "Choose plan",
                component: ChoosePlan,
                meta: {
                    title: 'Choose plan'
                },
            },
            {
                path: "/settings",
                name: "Settings",
                component: Settings,
                meta: {
                    title: 'Settings'
                },
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: 'Login'
        },
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: Forgot,
        meta: {
            title: 'Forgot password',
        },
    },
    {
        path: "/reset/:uid/:token",
        name: "Reset",
        component: Reset,
        meta: {
            title: 'Reset password',
        },
    },
    {
        path: "/confirm/:uid/:token",
        name: "Confirm",
        component: Confirm,
        meta: {
            title: 'Confirm'
        },
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: {
            title: 'Logout'
        },
    },
    {
        path: "/registration",
        name: "Registration",
        component: Registration,
        meta: {
            title: 'Registration'
        },
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
        meta: {
            title: '404'
        },
    },
    {
        path: "/g/:b64",
        name: "GoTo",
        component: GoTo,
        meta: {
            title: 'GoTo'
        },
    },
    {
        path: '/invoice/:id',
        name: 'Invoice',
        component: Invoice,
        meta: {
            title: 'Invoice'
        },
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            title: '404'
        }
    }
]
