<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Settings</h1>
                <p class="mb-6 text-muted" v-if="$root.context.id">
                    Please provide your Customer ID
                    <code>"{{ $root.context.id }}"</code>
                    when requesting support
                </p>
                <p class="mb-6 text-muted" v-else>
                    You can change the settings for your account here
                </p>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': tab == 'profile'}" @click="tab = 'profile'" href="#profile">
                            Profile
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': tab == 'password'}" @click="tab = 'password'" href="#password">
                            Password
                        </a>
                    </li>
                    <!-- <li class="nav-item"> -->
                    <!--     <a class="nav-link" :class="{'active': tab == 'notifications'}" @click="tab = 'notifications'" href="#notifications"> -->
                    <!--         Notifications -->
                    <!--     </a> -->
                    <!-- </li> -->
                </ul>
                <Profile v-if="tab == 'profile'" />
                <Password v-if="tab == 'password'" />
                <Notifications v-if="tab == 'notifications'" />
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Profile from "./Profile"
import Password from "./Password"
import Notifications from "./Notifications"

export default {
    components: {
        Profile,
        Password,
        Notifications
    },
    data() {
        return {
            tab: location.hash.slice(1) || 'profile'
        }
    },
}
</script>
