<template>
    <Spinner />
</template>
<script>
import Spinner from "./Spinner"
export default {
    components: { Spinner },
    created() {
        let url = atob(this.$route.params.b64);
        location.href = url;
    }
}
</script>
