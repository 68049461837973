var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-3 col-lg-3" }, [
        _c("span", { staticClass: "navbar-brand" }, [
          _vm._v(_vm._s(_vm.$root.siteName)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-gray-700 mb-2" }),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }, [
        _c("ul", { staticClass: "list-unstyled text-muted mb-0" }, [
          _c("li", { staticClass: "mb-3" }, [
            _c(
              "a",
              {
                staticClass: "text-reset text-nowrap",
                attrs: { href: `mailto:${_vm.$root.siteEmail}` },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$root.siteEmail) +
                    "\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }, [
      _c(
        "ul",
        { staticClass: "list-unstyled text-muted mb-6 mb-md-8 mb-lg-0" },
        [
          _c("li", { staticClass: "mb-3" }),
          _vm._v(" "),
          _c("li", { staticClass: "mb-3" }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }, [
      _c(
        "ul",
        { staticClass: "list-unstyled text-muted mb-6 mb-md-8 mb-lg-0" },
        [
          _c("li", { staticClass: "mb-3" }),
          _vm._v(" "),
          _c("li", { staticClass: "mb-3" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }