var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "pt-4 pt-md-11" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-7 col-lg-6 order-md-1" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "lead text-center text-md-start text-muted mb-2" },
              [
                _vm._v(
                  "\n                        XXXXXXXX XXXXXXX XXX XXX XXXXXXXXX XXX XXXXXXXXX\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "lead text-center text-md-start text-muted mb-2" },
              [
                _vm._v(
                  "\n                        XXXXXXXX XXXXXXX XXX XXX XXXXXXXXX XXX XXXXXXXXX,\n                        XXXXXXXX XXXXXXX XXX XXX XXXXXXXXX XXX XXXXXXXXX\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "lead text-center text-md-start text-muted mb-2 mb-lg-8",
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$root.context.id,
                    expression: "!$root.context.id",
                  },
                ],
                staticClass: "text-center text-md-start",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary shadow lift me-1",
                    attrs: { to: { path: "/login" } },
                  },
                  [
                    _vm._v("\n                            Login "),
                    _c("i", {
                      staticClass: "fe fe-arrow-right d-none d-md-inline ms-3",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary-soft lift",
                    attrs: { to: { path: "/registration" } },
                  },
                  [
                    _vm._v(
                      "\n                            Registration\n                        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("section", { staticClass: "py-1 py-md-2" }, [_c("Plans")], 1),
    _vm._v(" "),
    _c("section", { staticClass: "py-2 py-md-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-8 text-center" }, [
            _c(
              "h2",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$root.context.id,
                    expression: "!$root.context.id",
                  },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "fw-bold text-primary-desat",
                    attrs: { to: { path: "/registration" } },
                  },
                  [
                    _vm._v(
                      "\n                            Register\n                        "
                    ),
                  ]
                ),
                _vm._v(" and start using our service!\n                    "),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-muted mb-7 mb-md-9" }, [
              _vm._v(
                "\n                        XXXXXXXX XXXXXXX XXX XXX XXXXXXXXX XXX XXXXXXXXX\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-5 col-lg-6 order-md-2" }, [
      _c("img", {
        staticClass: "img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0",
        attrs: {
          src: "/assets/img/illustrations/illustration-3.png",
          alt: "...",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-4 text-center text-md-start" }, [
      _c("span", { staticClass: "text-primary text-nowrap" }, [
        _vm._v(
          "\n                            XXXXXXXXXX XXXXXXXXXXX\n                        "
        ),
      ]),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "pt-8 pb-4 pt-md-10 pb-md-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-10 text-center" }, [
            _c("h2", [
              _vm._v(
                "\n                        Now we are ready to work with "
              ),
              _c("span", { staticClass: "fw-bold text-primary-desat" }, [
                _vm._v(
                  "\n                            --------\n                        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("XXXXXXXX XXXXXXX XXX XXX XXXXXXXXX XXX XXXXXXXXX"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }