var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h1", { staticClass: "mb-0 fw-bold" }, [_vm._v("Billing")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-6 text-muted" }, [
              _vm._v(
                "\n                    Funds are debited hourly according to your chosen plan\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-5" }, [
              _vm.$root.context.amount
                ? _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _c("small", { staticClass: "text-gray-700" }, [
                        _vm._v(_vm._s(_vm.$root.context.currency_symbol)),
                      ]),
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$root.context.amount) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-gray-700" }, [
                      _vm._v("Balance"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-xl btn-primary",
                      attrs: { to: { path: "/billing/pay" } },
                    },
                    [
                      _vm._v(
                        "\n                                Top up your balance\n                            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "nav nav-tabs" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.tab == "payments" },
                    attrs: { href: "#payments" },
                    on: {
                      click: function ($event) {
                        _vm.tab = "payments"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Payments\n                        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.tab == "transactions" },
                    attrs: { href: "#transactions" },
                    on: {
                      click: function ($event) {
                        _vm.tab = "transactions"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Transactions\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.tab == "payments" ? _c("Payments") : _vm._e(),
            _vm._v(" "),
            _vm.tab == "transactions" ? _c("Transactions") : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }