var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        !_vm.loaded
          ? _c("Spinner")
          : _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("h1", { staticClass: "mb-0 fw-bold" }, [_vm._v("Plan")]),
                  _vm._v(" "),
                  _vm.current.name
                    ? _c("p", { staticClass: "mb-6 text-muted" }, [
                        _vm._v("\n                    Your current plan "),
                        _c(
                          "span",
                          { staticClass: "fw-bold text-primary-desat" },
                          [_vm._v(_vm._s(_vm.current.name))]
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Funds are debited hourly according to your chosen plan\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Plans", {
                    attrs: { button: "Choice", disabledId: _vm.current.id },
                  }),
                ],
                1
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }