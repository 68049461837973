var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-border border-primary" }, [
    _c("div", { staticClass: "container d-flex flex-column" }, [
      _c(
        "div",
        {
          staticClass:
            "row align-items-center justify-content-center gx-0 min-vh-100",
        },
        [
          _vm.invoice.id
            ? _c(
                "div",
                {
                  staticClass: "col-12 order-md-1 mb-auto mb-md-0 pb-8 py-md-6",
                },
                [
                  _c("h1", { staticClass: "display-3 fw-bold text-center" }, [
                    _vm._v(_vm._s(_vm.$root.siteName)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-5 text-center text-muted" }, [
                    _vm._v("Tax Invoice and Receipt"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-5 col-6" }, [
                    _c("dl", { staticClass: "row" }, [
                      _c("dt", { staticClass: "col-sm-3" }, [_vm._v("DATE")]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toDate")(parseInt(Date.now() / 1000))
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v("REFERENCE"),
                      ]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(_vm._s(_vm.invoice.id)),
                      ]),
                      _vm._v(" "),
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v("BILLED TO"),
                      ]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(_vm._s(_vm.$root.context.billed_to)),
                      ]),
                      _vm._v(" "),
                      _c("dt", { staticClass: "col-sm-3" }, [
                        _vm._v("ADDRESS"),
                      ]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(_vm._s(_vm.$root.context.address)),
                      ]),
                      _vm._v(" "),
                      _c("dt", { staticClass: "col-sm-3" }, [_vm._v("EMAIL")]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "col-sm-9" }, [
                        _vm._v(_vm._s(_vm.$root.context.email)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-striped" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n                                Communication and data service"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(_vm._f("toDate")(_vm.invoice.paid_at))
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.invoice.currency__symbol) + "0.00"),
                          _c("br"),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm.invoice.currency__code)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.invoice.currency__symbol) +
                              _vm._s(_vm.invoice.amount)
                          ),
                          _c("br"),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm.invoice.currency__code)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              "Payment " +
                                _vm._s(
                                  _vm.invoice.is_success
                                    ? "received"
                                    : "declined"
                                )
                            ),
                          ]),
                          _c("br"),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.invoice.currency__symbol) +
                              _vm._s(
                                _vm.invoice.is_success ? _vm.invoice.amount : 0
                              )
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm.invoice.currency__code)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center py-md-6" }, [
                    _vm._v(_vm._s(_vm.$root.siteName)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center py-md-4" }, [
                    _c(
                      "a",
                      { attrs: { href: `mailto:${_vm.$root.siteEmail}` } },
                      [_vm._v(_vm._s(_vm.$root.siteEmail))]
                    ),
                  ]),
                ]
              )
            : _c("Spinner"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DESCRIPTION")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("TAX")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("AMOUNT")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }