var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "position-relative pt-12 pt-md-14 mt-n11" }, [
      _c(
        "div",
        { staticClass: "shape shape-fluid-x shape-blur-2 svg-shim text-light" },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 1313 768",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M442.794 768c163.088 0 305.568-88.227 382.317-219.556l.183.556s.249-.749.762-2.181a440.362 440.362 0 0033.192-71.389C901.996 397.81 989.306 277.09 1144.29 206l-.92-.693C1230.34 171.296 1295.63 94.049 1312.83 0H1.294v295.514c-.663 9.909-1 19.908-1 29.986 0 244.386 198.114 442.5 442.5 442.5z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row align-items-center text-center text-md-start" },
        [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("img", {
              staticClass: "img-fluid mw-130 float-end mb-6 mb-md-0",
              attrs: {
                src: "/assets/img/illustrations/illustration-4.png",
                alt: "...",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("h1", { staticClass: "display-1 fw-bold" }, [
              _c("div", [_vm._v("Features")]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
              _vm._v(
                "\n                        Creation of blockchain accounts\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
              _vm._v(
                "\n                        Notification of an incoming transaction from the blockchain to your website (webhook)\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
              _vm._v(
                "\n                        Request information by token or wallet balances\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
              _vm._v(
                "\n                        Request for transaction details information\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
              _vm._v(
                "\n                        Soon there will be a withdrawal of funds to the wallet you specified\n                    "
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }