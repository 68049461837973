var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h1", { staticClass: "mb-0 fw-bold" }, [_vm._v("Settings")]),
            _vm._v(" "),
            _vm.$root.context.id
              ? _c("p", { staticClass: "mb-6 text-muted" }, [
                  _vm._v(
                    "\n                    Please provide your Customer ID\n                    "
                  ),
                  _c("code", [
                    _vm._v('"' + _vm._s(_vm.$root.context.id) + '"'),
                  ]),
                  _vm._v(
                    "\n                    when requesting support\n                "
                  ),
                ])
              : _c("p", { staticClass: "mb-6 text-muted" }, [
                  _vm._v(
                    "\n                    You can change the settings for your account here\n                "
                  ),
                ]),
            _vm._v(" "),
            _c("ul", { staticClass: "nav nav-tabs" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.tab == "profile" },
                    attrs: { href: "#profile" },
                    on: {
                      click: function ($event) {
                        _vm.tab = "profile"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Profile\n                        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.tab == "password" },
                    attrs: { href: "#password" },
                    on: {
                      click: function ($event) {
                        _vm.tab = "password"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Password\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.tab == "profile" ? _c("Profile") : _vm._e(),
            _vm._v(" "),
            _vm.tab == "password" ? _c("Password") : _vm._e(),
            _vm._v(" "),
            _vm.tab == "notifications" ? _c("Notifications") : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }