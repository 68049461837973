function cached(resource) {
    let get = resource.get;
    resource._cache = null;
    resource.get = function(params) {
        if (!resource._cache)
            resource._cache = get(params);
        return resource._cache;
    }
    return resource;
}

export default {
    Login() {
        return this.$resource(this.apiUrl + 'users/login')
    },
    Register() {
        return this.$resource(this.apiUrl + 'users/register')
    },
    Context() {
        return this.$resource(this.apiUrl + 'users/context')
    },
    Logout() {
        return this.$resource(this.apiUrl + 'users/logout')
    },
    Forgot() {
        return this.$resource(this.apiUrl + 'users/forgot')
    },
    Reset() {
        return this.$resource(this.apiUrl + 'users/reset')
    },
    Password() {
        return this.$resource(this.apiUrl + 'users/password')
    },
    Plan() {
        return this.$resource(this.apiUrl + 'users/plan')
    },
    WikiArticle() {
        return this.$resource(this.apiUrl + 'wiki/article/{slug}')
    },
    Data() {
        return cached(this.$resource(this.apiUrl + 'analytics/data'));
    },
    Item() {
        return this.$resource(this.apiUrl + 'goods/item')
    },
    Transaction() {
        return this.$resource(this.apiUrl + 'users/transaction')
    },
    Operation() {
        return this.$resource(this.apiUrl + 'users/operation')
    },
    Invoice() {
        return this.$resource(this.apiUrl + 'invoice/{id}')
    },
}
