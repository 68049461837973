<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Billing</h1>
                <p class="mb-6 text-muted">
                    Funds are debited hourly according to your chosen plan
                </p>
                    <div class="row mb-5">
                        <div class="col" v-if="$root.context.amount">
                            <h4 class="mb-0">
                                <small class="text-gray-700">{{ $root.context.currency_symbol }}</small>
                                {{ $root.context.amount }}
                            </h4>
                            <small class="text-gray-700">Balance</small>
                        </div>
                        <div class="col-auto">
                            <router-link :to="{path: '/billing/pay'}" class="btn btn-xl btn-primary">
                                Top up your balance
                            </router-link>
                        </div>
                    </div>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': tab == 'payments'}" @click="tab = 'payments'" href="#payments">
                            Payments
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': tab == 'transactions'}" @click="tab = 'transactions'" href="#transactions">
                            Transactions
                        </a>
                    </li>
                </ul>
                <Payments v-if="tab == 'payments'" />
                <Transactions v-if="tab == 'transactions'" />
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Payments from "./Payments"
import Transactions from "./Transactions"

export default {
    components: {
        Payments,
        Transactions
    },
    data() {
        return {
            tab: location.hash.slice(1) || 'payments'
        }
    },
}
</script>
